const detectImageFormat = (base64String) => {
    if (base64String.startsWith('iVBORw0KG')) {
      return 'png';  // PNG image
    } else if (base64String.startsWith('/9j/')) {
      return 'jpeg'; // JPEG image
    } else if (base64String.startsWith('R0lGODlh')) {
      return 'gif';  // GIF image
    } else {
      return 'jpeg'; // Fallback format
    }
  };
  
  const Base64Image = ({ base64String  }) => {
    if (!base64String) {
        return <p>No image data provided.</p>;  // Handle missing base64 string
      }
    
      // Detect the image format
      const format = detectImageFormat(base64String);
    
      if (!format) {
        return <p>Invalid image format.</p>;  // Handle invalid or unrecognized format
      }
    
      // Add the MIME type prefix to the base64 string
      const imageSrc = `data:image/${format};base64,${base64String}`;
    
      return (
        <div>
          <img src={imageSrc} alt="Base64" style={{ width: '200px', height: '200px' }} />
        </div>
      );
  };

  export default Base64Image;