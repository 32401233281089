import React, { useEffect, useState } from 'react'
import cookie from "react-cookies";
import { UNIFIED_BASE_URL } from '../../webbase';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
function DirectFunding() {
    const [users, setUsers] = useState([])
    const [amount, setAmount] = useState('')
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [des, setDes] = useState('')
   const token= cookie.load("token")
   useEffect(() =>{
 
        axios
            .post(UNIFIED_BASE_URL + "/onboarding/all-merchants",{} ,{
                headers: {
                    authorization: token,
                },
            })
            .then((res) => {
                console.log(res.data);
                if (res.data.status === "2000") {
                  setUsers(res.data.data.data)

                    // this.getTransactions();
                } else {
                    toast.error(res.data.description);
                }
            })
            .catch((err) => {
                console.log(err);
            });
   

   },[token])
   const handleChange = (event) => {
    const selectedUser = users.find(user => user.businessName === event.target.value);
    setEmail(selectedUser.businessEmail)
    setName(selectedUser.businessName)
   
  };

 const onSubmit = (event) => {
    event.preventDefault();
    const id = toast.loading("Please wait...");
    
    const data = {
      "amount": amount,
     "merchantEmail": email,
     "narration": des,
     "reciepientAccountName": name,
    };
    console.log(data);
    fetch(UNIFIED_BASE_URL + "/manage/fund-vas-direct", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res.status === "2000") {
          toast.update(id, {
            render: "Successfully Invited Dealer",
            type: "success",
            isLoading: false,
          });
          window.location.reload()
        } else {
          toast.update(id, {
            render: res.description,
            type: "error",
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <main className="page-content">
    <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  VAS direct funding
                </li>
              </ol>
            </nav>
          </div>
         
        </div>
        <div className=' card my-4 p-4 '>
            <form 
            onSubmit={onSubmit}
            className=' row'>
            <div class="col-6">
                <label class="form-label">Amount</label>
                <input
                  type="text"
                  name="Amount"
                  class="form-control"
                  style={{ borderRadius: "17px" }}
                  value={amount}
                  onChange={(event) => {
                   setAmount(event.target.value);
                  }}
                  required
                />
              </div>
              <div class="col-6">
                <label class="form-label">Naration</label>
                <input
                  type="text"
                  name="Narration"
                  class="form-control"
                  style={{ borderRadius: "17px" }}
                  value={des}
                  onChange={(event) => {
                   setDes(event.target.value);
                  }}
                  required
                />
              </div>
              <div class="col-6 mt-3">
                <label class="form-label">Select Merchant</label>
                 <select
                   className="form-select"
                    value={name}
                       onChange={handleChange}
                                >
                                    {
                                        users.map((user, index) => (
                                            <option key={index} value={user?.businessName}>{user?.businessName}</option>
                                        ))
                                    }
                                </select>
              </div>
              <div class="col-6 mt-3">
                <label class="form-label">Merchant Email</label>
                <input
                  type="text"
                  name="Narration"
                  class="form-control"
                  style={{ borderRadius: "17px" }}
                  value={email}
                  disabled
                />
              </div>
              <div class="text-start mt-3">
                <input
                  type="submit"
                  class="btn btn-primary px-4"
                  value="fund"
                />
              </div>
            </form>
          </div>
    </main>
  )
}

export default DirectFunding