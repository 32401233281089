// import React from "react";
// import { toast, ToastContainer } from "react-toastify";
// import { WEB_BASE_URL } from "../../webbase";
// import cookie from "react-cookies";
// import Select from "react-select";

// class CreateMerchant extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       businessEmail: "",
//       dealerId: "",
//       formState: "",
//       password: "",
//       stateOptions: [
//         //states in Nigeria
//         { label: "Abia", value: "Abia" },
//         { label: "Adamawa", value: "Adamawa" },
//         { label: "Akwa Ibom", value: "Akwa Ibom" },
//         { label: "Anambra", value: "Anambra" },
//         { label: "Bauchi", value: "Bauchi" },
//         { label: "Bayelsa", value: "Bayelsa" },
//         { label: "Benue", value: "Benue" },
//         { label: "Borno", value: "Borno" },
//         { label: "Cross River", value: "Cross River" },
//         { label: "Delta", value: "Delta" },
//         { label: "Ebonyi", value: "Ebonyi" },
//         { label: "Edo", value: "Edo" },
//         { label: "Ekiti", value: "Ekiti" },
//         { label: "Enugu", value: "Enugu" },
//         { label: "Gombe", value: "Gombe" },
//         { label: "Imo", value: "Imo" },
//         { label: "Jigawa", value: "Jigawa" },
//         { label: "Kaduna", value: "Kaduna" },
//         { label: "Kano", value: "Kano" },
//         { label: "Katsina", value: "Katsina" },
//         { label: "Kebbi", value: "Kebbi" },
//         { label: "Kogi", value: "Kogi" },
//         { label: "Kwara", value: "Kwara" },
//         { label: "Lagos", value: "Lagos" },
//         { label: "Nasarawa", value: "Nasarawa" },
//         { label: "Niger", value: "Niger" },
//         { label: "Ogun", value: "Ogun" },
//         { label: "Ondo", value: "Ondo" },
//         { label: "Osun", value: "Osun" },
//         { label: "Oyo", value: "Oyo" },
//         { label: "Plateau", value: "Plateau" },
//         { label: "Rivers", value: "Rivers" },
//         { label: "Sokoto", value: "Sokoto" },
//         { label: "Taraba", value: "Taraba" },
//         { label: "Yobe", value: "Yobe" },
//         { label: "Zamfara", value: "Zamfara" },
//       ],
//       token: cookie.load("tokenAdmin"),
//     };

//     this.onChangeState = this.onChangeState.bind(this);
//   }

//   onSubmit = (event) => {
//     event.preventDefault();
//     const id = toast.loading("Please wait...");
//     const { businessEmail, businessName } = this.state;
//     if (this.state.formState == "") {
//       alert("State is invalid. Select a state");
//       return;
//     }
//     const { history } = this.props;
//     const data = {
//       bussinessEmail: businessEmail,
//       bussinessName: this.state.dealerId,
//       bussinessAlias: "",
//       states: this.state.formState,
//     };
//     console.log(data);
//     fetch(WEB_BASE_URL + "/admin/invite-dealer", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: this.state.token,
//       },
//       body: JSON.stringify(data),
//     })
//       .then((res) => res.json())
//       .then((res) => {
//         console.log(res);
//         if (res.status === "2000") {
//           toast.update(id, {
//             render: "Successfully Invited Dealer",
//             type: "success",
//             isLoading: false,
//           });
//           this.props.navigate("/logical/view-dealers");
//         } else {
//           toast.update(id, {
//             render: res.description,
//             type: "error",
//             isLoading: false,
//           });
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   onChangeState = (e) => {
//     this.setState({
//       formState: e.value,
//     });
//   };

//   render() {
//     const customStyles = {
//       menu: (provided, state) => ({
//         ...provided,
//         borderBottom: "1px dotted pink",
//         color: state.selectProps.menuColor,
//         padding: 18,
//       }),

//       control: (provided, { selectProps: { width } }) => ({
//         ...provided,
//         borderRadius: "1px",
//         padding: "2px 4px",
//         width: width,
//         minWidth: "310px",
//       }),
//     };
//     return (
//       <main className="page-content">
//         <ToastContainer
//           position="top-center"
//           autoClose={3000}
//           hideProgressBar={false}
//           newestOnTop={false}
//           closeOnClick
//           rtl={false}
//           pauseOnFocusLoss
//           draggable
//           pauseOnHover
//         />
//         <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
//           <div className="ps-3">
//             <nav aria-label="breadcrumb">
//               <ol className="breadcrumb mb-0 p-0">
//                 <li className="breadcrumb-item">
//                   <a href="javascript:;">
//                     <i className="bx bx-home-alt"></i>
//                   </a>
//                 </li>
//                 <li className="breadcrumb-item active" aria-current="page">
//                   Create a Topupcliq Merchant
//                 </li>
//               </ol>
//             </nav>
//           </div>
//         </div>
//         <div className="card">
//           <div className="card-body">
//             <form
//               autocomplete="off"
//               class="row g-3"
//               onSubmit={(event) => {
//                 this.onSubmit(event);
//               }}
//             >

//               <div class="col-6">
//                 <label class="form-label">Email</label>
//                 <input
//                   type="email"
//                   name="businessEmail"
//                   class="form-control"
//                   style={{ borderRadius: "17px" }}
//                   value={this.state.businessEmail}
//                   onChange={(event) => {
//                     this.setState({
//                       businessEmail: event.target.value,
//                     });
//                   }}
//                   required
//                 />
//               </div>
//               <div class="col-6">
//                 <label class="form-label">Password</label>
//                 <input
//                   type="password"
//                   name="password"
//                   class="form-control"
//                   style={{ borderRadius: "17px" }}
//                   value={this.state.password}
//                   onChange={(event) => {
//                     this.setState({
//                       password: event.target.value,
//                     });
//                   }}
//                   required
//                 />
//               </div>
//               <div class="col-6">
//                 <label class="form-label">DSP</label>
//                 <input
//                   type="text"
//                   name="dealerId"
//                   class="form-control"
//                   style={{ borderRadius: "17px" }}
//                   value={this.state.dealerId}
//                   onChange={(event) => {
//                     this.setState({ dealerId: event.target.value });
//                   }}
//                   required
//                 />
//               </div>
//               <div class="text-start">
//                 <input
//                   type="submit"
//                   class="btn btn-primary px-4"
//                   value="Invite Dealer"
//                 />
//               </div>
//             </form>
//           </div>
//         </div>
//       </main>
//     );
//   }

//   componentDidMount() { }
// }

// export default CreateMerchant;


import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from "react-toastify";
import { WEB_BASE_URL,CLIQ_BASE_URL } from "../../webbase";
import cookie from "react-cookies";
import axios from 'axios';

function DailyLimit() {
  const token = cookie.load("token");
  const [transaction, setTransaction] = useState({})
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(!open)
  const [unit, setUnit] = useState()
  const [data, setData] = useState()
  const [logic, setLogic] = useState()
  const [recharge, setRecharge] = useState()
  const handleClose = () => setOpen(false)

  useEffect(() =>{
    axios
    .get(CLIQ_BASE_URL + "/api/v3/w3/daily-limits", {
      headers: {
        authorization: token,
      },
    })
    .then((res) => {
      //check if data isnt null
      if (res.data.data !== null) {
         setTransaction(res.data.data)
      }
      else {
          setTransaction({})
      }
    })
    .catch((err) => {
      console.log(err);
    });
  },[token])
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN",
  });
  const onSubmit = (e) =>{
    e.preventDefault();
    const id = toast.loading("Please wait...");
   axios.post(CLIQ_BASE_URL + "/api/v3/w3/daily-limits",
    {
     
      "dailyUnits": unit,
      "dataDailyLimits": data,
      "logicalDaiyLimits": logic,
      "rechargeDailyLimit": recharge
    }
    ,{
      headers: {
        authorization: token,
      },
    })
    .then((res) => {
      //check if data isnt null
      if (res.data.status === "2000") {
        toast.update(id, {
          render: res.data.description,
          type: "success",
          isLoading: false,
        });
        window.location.reload()
      }
      
      else {
        toast.update(id, {
          render: res.data.description,
          type: "error",
          isLoading: false,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }
  return (
    <main className="page-content">
    <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
   
     <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
     {/* <SetLimitModal
      open={open}
      handleClose={handleClose}
      main={transaction}
    /> */}
    
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Daily Limit
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="ms-auto">
            <div className="btn-group">
              <button onClick={handleOpen} type="button" className="btn btn-primary">
             {open?'Hide Limit':"Set Limit"} 
              </button>
            </div>
          </div>
     {open &&
     <form onSubmit={onSubmit}>
     <div className=' row p-3'>
          <div className=' col-6'>
      <div className=' p-2'>
        <label className="form-label">Daily Units</label>
        <input
              className="form-control"
              type="text"
              required
              value={unit}
              onChange={(e) =>setUnit(e.target.value)}
              placeholder="10"
            />
      </div> 
      <div className=' p-2'>
        <label className="form-label">Data Daily Limit</label>
        <input
              className="form-control"
              type="text"
              placeholder="10"
              required
              value={data}
              onChange={(e) =>setData(e.target.value)}
            />
      </div> 
      <div className=' p-2'>
        <label className="form-label">Logical Daily Limit</label>
        <input
              className="form-control"
              type="text"
              required
              placeholder="10"
              value={logic}
              onChange={(e) =>setLogic(e.target.value)}   
            />
      </div> 
      <div className=' p-2'>
        <label className="form-label">Recharge Daily Limit</label>
        <input
              className="form-control"
              type="text"
              required
              placeholder="10"
              value={recharge}
              onChange={(e) =>setRecharge(e.target.value)}
            />
      </div> 
      <div className="btn-group">
              <button onClick={onSubmit} type="button" className="btn btn-primary">
              Set Limit
              </button>
      </div>
          </div>
     </div>
      
     </form>
     }
        <div className=' my-4  row'>
            <div className=' card p-2 col-6'>
            <h1>Daily limit </h1>
              <p>Daily Unit- {transaction?.dailyUnits}</p>
              <p>Onetime limit-{formatter.format(transaction?.oneTimeLimit)} </p>
              <p>Onetime limit BVN- {formatter.format(transaction?.oneTimeLimitBvn)}</p>
              <p>Daily Limit-{formatter.format(transaction?.dailyLimit)} </p>
              <p>Daily Limit BVN-{formatter.format(transaction?.dailyLimitBvn)} </p>
              <p>Bills daily Limit-{formatter.format(transaction?.billsDailyLimits)} </p>
              <p>Bills daily Limit BVN-{formatter.format(transaction?.billsDailyLimitsBvn)}</p>
            </div>
        </div>
    </main>
  )
}

export default DailyLimit