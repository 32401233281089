import React from "react";
import axios from "axios";
import cookie from "react-cookies";
import { WEB_BASE_URL } from "./webbase";
import { toast } from "react-toastify";
class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: cookie.load("user"),
      token: cookie.load("token"),
      users:[],
      formRole: "",
    };
    this.getRoleList = this.getRoleList.bind(this);
  }
  getRoleList() {
    axios
      .get(WEB_BASE_URL+ "/admin/get-roles",{
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res.data);
        this.setState({
          users: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onInviteUser(event) {
    event.preventDefault();
    
      const id = toast.loading("Please wait...");
      const user = {
       "transactionPin": "844607",
        emailAddress: this.state.formEmail,
        newRole: this.state.formRole,
      };
      axios
        .post(WEB_BASE_URL + "/admin/invite-user", user, {
          headers: {
            authorization: this.state.token,
          },
        })
        .then((res) => {
          if (res.data.status === "2000") {
            toast.update(id, {
              render: res.data.description,
              type: "success",
              isLoading: false,
            });
          } else {
            console.log(res);
            toast.update(id, {
              render:res.data.description,
              type: "error",
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          toast.update(id, {
            render: err,
            type: "error",
            isLoading: false,
          });
        });
    
  }
  onChangeRole(event) {
    this.setState({
      formRole: event.target.value,
    });
  }
  componentDidMount() {
    this.getRoleList();
  }
  render() {
    let ifEmailEditable = this.props.title === "Edit User" ? true : false;
    return (
      <div
        className="modal fade"
        id={this.props.id}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.props.title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form
                 onSubmit={(e) => {
                this.onInviteUser(e);
              }}
               className="row g-3">
                <div className="col-12">
                  <label className="form-label">User Email</label>
                  <input
                    onChange={(event) => this.props.onChangeEmail(event)}
                    type="email"
                    className="form-control"
                    value={this.props.formEmail}
                    disabled={ifEmailEditable}
                  />

                </div>
                {/* <div className="col-12">
                  <label className="form-label">Role</label>
                  <select
                    className="form-select mb-3"
                    aria-label="Default select example"
                    value={this.props.formRole}
                    onChange={(event) => this.props.onChangeRole(event)}
                  >
                    <option selected=""> Select Role</option>
                     <option value="TOPUPBOX_MANAGER">TOPUPBOX_MANAGER</option>
                    <option value="TOPUPBOX_ADMIN">TOPUPBOX_ADMIN</option>
                  </select>
                </div> */}
                <div className="col-12">
                  <label className="form-label">Role</label>
                  <select
                    className="form-select mb-3"
                    aria-label="Default select example"
                    value={this.state.formRole}
                    onChange={(event) => this.onChangeRole(event)}
                    required
                  >
                    <option selected=""> Select Role</option>
                    {this.state.users.map((e, index) =>{
                      return <option key={index} value={e.inviteRole}>{e.inviteRole}</option>
                    })}
                    {/* <option value="TOPUPBOX_OPERATOR">TOPUPBOX_OPERATOR</option>
                    <option value="TOPUPBOX_MANAGER">TOPUPBOX_MANAGER</option>
                    <option value="TOPUPBOX_ADMIN">TOPUPBOX_ADMIN</option> */}
                  </select>
                </div>
                <div className="modal-footer">
              <button
               
                type="button"
                className="btn btn-primary"
              >
                {this.props.buttonText}
              </button>
            </div>
              </form>
              
            </div>
            
          </div>
        </div>
      </div>
    );
  }

  // componentDidMount() {
  //   this.setState({
  //     someKey: "otherValue",
  //   });
  // }
}

export default Modal;
