import React from "react";
import Modal from "./modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import cookie from "react-cookies";
import { CLIQ_BASE_URL, UNIFIED_BASE_URL, WEB_BASE_URL } from "../../webbase";

class WhiteLabelRequests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: cookie.load("user"),
            token: cookie.load("token"),
            users: [],
        };
        console.log(this.state.token);
        this.onInviteUser = this.onInviteUser.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.approve = this.approve.bind(this);
    }

    onInviteUser(event) {
        event.preventDefault();
        if (this.state.formEmail !== "" && this.state.formRole !== "") {
            toast.success("User Role Edited!!!");
        } else {
            toast.error("User Role Edit failed!!!");
        }
    }

    approve({ dsp, emailAddress, network, secret }) {
        //event.preventDefault();
        const id = toast.loading("Please wait...");
        const data = {
            "defaultAccBalance": 0,
            "dsp": dsp,
            "emailAddress": emailAddress,
            "network": network,
            "secret": secret
        };
        console.log(data);
        axios.post(UNIFIED_BASE_URL + "/manage/approve-mobile-request", data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: this.state.token,
            },
        }).then((res) => {
            if (res.data.status === "2000") {
                toast.update(id, {
                    render: "Successfully Approved Mobile App Request",
                    type: "success",
                    isLoading: false,
                });
                this.props.navigate("/");
            } else {
                toast.update(id, {
                    render: res.data.description,
                    type: "error",
                    isLoading: false,
                });
            }
        })
            .catch((err) => {
                console.log(err);
                toast.update(id, {
                    render: "Error Approving Mobile App Request",
                    type: "error",
                    isLoading: false,
                });
            }).finally(() => {
                setTimeout(() => {
                    toast.dismiss();
                }, 2000)
            });
    }

    //get all users
    getUsers() {
        axios
            .get(WEB_BASE_URL + "/admin/all-mobile-request", {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                console.log(res.data.data.data);
                this.setState({
                    users: res.data.data.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }


    componentDidMount() {
        this.getUsers();
    }

    render() {
        return (
            <main className="page-content">
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item">
                                    <a href="javascript:;">
                                        <i className="bx bx-home-alt"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    WhiteLabels
                                </li>
                            </ol>
                        </nav>
                    </div>

                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <h5 className="mb-0">All Requests for Mobile App</h5>
                            <form className="ms-auto position-relative">
                                <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                    <i className="bi bi-search"></i>
                                </div>
                                <input
                                    className="form-control ps-5"
                                    type="text"
                                    placeholder="search"
                                />
                            </form>
                        </div>
                        <div className="table-responsive mt-3">
                            <table className="table align-middle">
                                <thead className="table-secondary">
                                    <tr>
                                        <th>S/N</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.users?.map((user, index) => (
                                        <>
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{user?.emailAddress}</td>
                                                <td>{user?.status}</td>
                                                <td>
                                                    <div className="table-actions d-flex align-items-center gap-3 fs-6">
                                                        {
                                                            user?.status == "pending" ? (
                                                                <a
                                                                    href="/"
                                                                    className="text-success"
                                                                    title="Approve Requests"
                                                                    onClick={
                                                                        (e) => {
                                                                            e.preventDefault();
                                                                            this.approve(user?.dsp, user?.emailAddress, "MTN", user?.secret,);
                                                                        }
                                                                    }
                                                                >
                                                                    Approve
                                                                </a>
                                                            ) : (
                                                                <a
                                                                    href="/"
                                                                    className="text-danger"
                                                                    title="Disable Mobile App"
                                                                    onClick={
                                                                        (e) => {
                                                                            e.preventDefault();
                                                                            this.approve(user?.dsp, user?.emailAddress, "MTN", user?.secret);
                                                                        }
                                                                    }
                                                                >
                                                                    Disable
                                                                </a>
                                                            )
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                            <Modal
                                                title="Fund User"
                                                id={"editModal" + index}
                                                buttonText="Add funds"
                                                onSubmit={this.onFundUser}
                                                onChangeEmail={this.onChangeEmail}
                                                onChangeRole={this.onChangeRole}
                                                formEmail={user?.emailAddress}
                                            ></Modal>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default WhiteLabelRequests;
