import React from "react";
import { toast, ToastContainer } from "react-toastify";
import { WEB_BASE_URL,UNIFIED_BASE_URL } from "../../webbase";
import cookie from "react-cookies";
import Select from "react-select";
import States from "../../../states";

class InviteMerchant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      businessEmail: "",
      dealerId: "",
      formState: "",
      selectedLocal: [],
      stateOptions: [
        //states in Nigeria
        { label: "Abia", value: "Abia" },
        { label: "Adamawa", value: "Adamawa" },
        { label: "Akwa Ibom", value: "Akwa Ibom" },
        { label: "Anambra", value: "Anambra" },
        { label: "Bauchi", value: "Bauchi" },
        { label: "Bayelsa", value: "Bayelsa" },
        { label: "Benue", value: "Benue" },
        { label: "Borno", value: "Borno" },
        { label: "Cross River", value: "Cross River" },
        { label: "Delta", value: "Delta" },
        { label: "Ebonyi", value: "Ebonyi" },
        { label: "Edo", value: "Edo" },
        { label: "Ekiti", value: "Ekiti" },
        { label: "Enugu", value: "Enugu" },
        { label: "Gombe", value: "Gombe" },
        { label: "Imo", value: "Imo" },
        { label: "Jigawa", value: "Jigawa" },
        { label: "Kaduna", value: "Kaduna" },
        { label: "Kano", value: "Kano" },
        { label: "Katsina", value: "Katsina" },
        { label: "Kebbi", value: "Kebbi" },
        { label: "Kogi", value: "Kogi" },
        { label: "Kwara", value: "Kwara" },
        { label: "Lagos", value: "Lagos" },
        { label: "Nasarawa", value: "Nasarawa" },
        { label: "Niger", value: "Niger" },
        { label: "Ogun", value: "Ogun" },
        { label: "Ondo", value: "Ondo" },
        { label: "Osun", value: "Osun" },
        { label: "Oyo", value: "Oyo" },
        { label: "Plateau", value: "Plateau" },
        { label: "Rivers", value: "Rivers" },
        { label: "Sokoto", value: "Sokoto" },
        { label: "Taraba", value: "Taraba" },
        { label: "Yobe", value: "Yobe" },
        { label: "Zamfara", value: "Zamfara" },
      ],
      token: cookie.load("tokenAdmin"),
      localGovs: States,
      localGovsList: [],
    };

    this.onChangeState = this.onChangeState.bind(this);
    this.onChangeLgas = this.onChangeLgas.bind(this);
    this.convertToFormat = this.convertToFormat.bind(this);
  }

  onSubmit = (event) => {
    event.preventDefault();
    const id = toast.loading("Please wait...");
    const { businessEmail, businessName } = this.state;
    // if (this.state.formState == "") {
    //   alert("State is invalid. Select a state");
    //   return;
    // }
    const { history } = this.props;
    const data = {
      businessEmail: businessEmail,
      businessName: this.state.dealerId,
      businessAlias: this.state.dealerId,
      merchantEmail:businessEmail,
      emailAddress:businessEmail,
      firstName:this.state.firstName,
      lastName:this.state.lastName,
      address:this.state.address,
      localGovArea:[
        "surulere"
      ],
      categoryId: 636000,
      states: this.state.formState,
       userRole: "MERCHANT"
    };
    console.log(data);
    fetch(UNIFIED_BASE_URL + "/onboarding/invite-user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res.status === "2000") {
          toast.update(id, {
            render: res.description,
            type: "success",
            isLoading: false,
          });
          window.location.window()
        } else {
          toast.update(id, {
            render: res.description,
            type: "error",
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  convertToFormat = (lgas) => {
    return lgas.map((lga) => ({
      value: lga.toLowerCase().replace(/\s+/g, '-'),
      label: lga
    }));
  }

  onChangeState = (e) => {
    console.log(this.state.localGovs.find((obj) => obj.name === e.value).lgas)
    this.setState({
      formState: e.value,
      selectedLocal: this.convertToFormat(this.state.localGovs.find((obj) => obj.name === e.value).lgas)
    });

  };

  onChangeLgas = (e) => {
    this.setState({
      localGovsList: e.value
    });

  };

  render() {
    const customStyles = {
      menu: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        color: state.selectProps.menuColor,
        padding: 18,
      }),

      control: (provided, { selectProps: { width } }) => ({
        ...provided,
        borderRadius: "1px",
        padding: "2px 4px",
        width: width,
        minWidth: "310px",
      }),
    };
    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Invite Merchant
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <form
              autocomplete="off"
              class="row g-3"
              onSubmit={(event) => {
                this.onSubmit(event);
              }}
            >
             <div class="col-6">
                <label class="form-label">Firstname</label>
                <input
                  type="text"
                  name="firstname"
                  class="form-control"
                  style={{ borderRadius: "17px" }}
                  value={this.state.firstName}
                  onChange={(event) => {
                    this.setState({ firstName: event.target.value });
                  }}
                  required
                />
              </div>
              <div class="col-6">
                <label class="form-label">Lastname</label>
                <input
                  type="text"
                  name="dealerId"
                  class="form-control"
                  style={{ borderRadius: "17px" }}
                  value={this.state.lastName}
                  onChange={(event) => {
                    this.setState({ lastName: event.target.value });
                  }}
                  required
                />
              </div>
              <div class="col-6">
                <label class="form-label">Business Name</label>
                <input
                  type="text"
                  name="dealerId"
                  class="form-control"
                  style={{ borderRadius: "17px" }}
                  value={this.state.dealerId}
                  onChange={(event) => {
                    this.setState({ dealerId: event.target.value });
                  }}
                  required
                />
              </div>

              <div class="col-6">
                <label class="form-label">Business Email</label>
                <input
                  type="email"
                  name="businessEmail"
                  class="form-control"
                  style={{ borderRadius: "17px" }}
                  value={this.state.businessEmail}
                  onChange={(event) => {
                    this.setState({
                      businessEmail: event.target.value,
                    });
                  }}
                  required
                />
              </div>

              {/* <div className="col-6">
                <label className="form-label">State</label>
                <div className="ms-auto position-relative">
                  <div className="position-absolute top-50 translate-middle-y search-icon px-3"></div>
                  <Select
                    onChange={this.onChangeState}
                    options={this.state.stateOptions}
                    styles={customStyles}
                    width="100%"
                    placeholder="Select State"
                    borderRadius="17px"
                  />
                </div>
              </div> */}
              {
                this.state.formState != "" ?
                  <div div className="col-6">
                    <label className="form-label">Local Government Areas</label>
                    <div className="ms-auto position-relative">
                      <div className="position-absolute top-50 translate-middle-y search-icon px-3"></div>
                      <Select
                        isMulti
                        onChange={this.onChangeLgas}
                        options={this.state.selectedLocal}
                        styles={customStyles}
                        width="100%"
                        value={this.state.localGovsList}
                        placeholder="Select State"
                        borderRadius="17px"
                      />
                    </div>
                  </div> : <></>
              }
              <div class="col-6">
                <label class="form-label">Address</label>
                <input
                  type="text"
                  name="Address"
                  class="form-control"
                  style={{ borderRadius: "17px" }}
                  value={this.state.address}
                  onChange={(event) => {
                    this.setState({
                      address: event.target.value,
                    });
                  }}
                  required
                />
              </div>


              <div class="text-start">
                <input
                  type="submit"
                  class="btn btn-primary px-4"
                  value="Invite Merchant"
                />
              </div>
            </form>
          </div>
        </div >
      </main >
    );
  }

  componentDidMount() { }
}

export default InviteMerchant;
