import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import cookie from "react-cookies";
import { ENTERPRISE_URL, WEB_BASE_URL } from "../../webbase";
import SetCommissionModal from "./setCommissionModal";

const DealerCommissions = () => {
  const [userEmail, setUserEmail] = useState(cookie.load("useAdmin"));
  const [token, setToken] = useState(cookie.load("tokenAdmin"));
  const [ratios, setRatios] = useState([]);
  const [checked, setChecked] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedDealer1, setSelectedDealer1] = useState("");
  const [selectedDealer2, setSelectedDealer2] = useState("");
  const [dealer1Ratio, setDealer1Ratio] = useState(1);
  const [dealer2Ratio, setDealer2Ratio] = useState(1);
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [merchantId, setMerchantId] = useState("");
  const [com,setCom] =useState("")
  const [dealerId, setDealerId] = useState('')
  const [networkId, setNetworkId] = useState('')

  const network = [
    { id: 1, name: "MTN" },
    { id: 2, name: "Airtel" },
    { id: 3, name: "Glo" },
    { id: 4, name: "9mobile" },
  ];

  useEffect(() => {
    getRatios();
    getDealers();
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    const data = {
      dealerId: selectedDealer1 + "," + selectedDealer2,
      telconame: selectedNetwork,
      merchantId: merchantId,
      dealer1Ratio: dealer1Ratio,
    };

    axios
      .post(ENTERPRISE_URL + "/manage/switch-or-ratio-dealer", data, {
        headers: {
          authorization: token,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "2000") {
          toast.success(res.data.description);
          getRatios();
        } else {
          toast.error(res.data.description);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRatios = () => {
    axios
      .get(ENTERPRISE_URL + "/manage/get-dealer-commission-network", {
        headers: {
          authorization: token,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "2000") {
          setRatios(res.data.data);
        } else {
          toast.error(res.data.description);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDealers = () => {
    axios
      .get(WEB_BASE_URL + "/admin/get-logical-dealer", {
        headers: {
          authorization: token,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "2000") {
          setUsers(res.data.data.data);
          setSelectedDealer1(res.data.data.data[0].dealerId);
        } else {
          toast.error(res.data.description);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
 const [open, setOpen] = useState(false)
 const handleOpen = () =>setOpen(true)
 const handleClose = () => setOpen(false)
  return (
    <main className="page-content">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div className="ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item">
                <a href="javascript:;">
                  <i className="bx bx-home-alt"></i>
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                All Dealer Commissions
              </li>
            </ol>
          </nav>
        </div>
        <div className="ms-auto"></div>
      </div>
      {open && <SetCommissionModal
            closeModal= {handleClose}
            commission= {com}
            dealerId= {dealerId}
            networkId= {networkId}
        />}  
      <div className="card">
        <div className="card-body">
          <form className="row">
            <div className="row">
              {/* <div className="col-lg-3 col-6 col-md-3">
                <label className="form-label">Dealer</label>
                <select
                  className="form-select"
                  value={selectedDealer1}
                  onChange={(event) => {
                    setSelectedDealer1(event.target.value);
                  }}
                >
                  {users.map((user, index) => (
                    <option key={index} value={user?.dealerId}>
                      {user?.dealerId}
                    </option>
                  ))}
                </select>
              </div> */}

              <div className="col-3">
                <label className="form-label">Network</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={selectedNetwork}
                  onChange={(event) => {
                    setSelectedNetwork(event.target.value);
                  }}
                >
                  {network.map((network, index) => (
                    <option key={index} value={network.id}>
                      {network.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-3">
                <label className="form-label">Commission</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Merchant ID"
                  value={merchantId}
                  onChange={(event) => {
                    setMerchantId(event.target.value);
                  }}
                />
              </div>
            </div>
            {/* <div className="row">
              <div className="col-3" style={{ marginTop: "2.7rem" }}>
                <button
                  className="btn btn-primary d-flex"
                  type="button"
                  style={{ width: "100%" }}
                  onClick={(event) => {
                    event.preventDefault();
                    onSubmit(event);
                  }}
                >
                  <div className="mx-1">Effect Commission</div>
                </button>
              </div>
            </div> */}
          </form>
        </div>
      </div>

      <div className="card">
        <div className="card-header py-3">
          <div className="row g-3">
            <div className="col-lg-2 col-6 col-md-3">
              <select className="form-select">
                <option>Show 10</option>
                <option>Show 30</option>
                <option>Show 50</option>
              </select>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table
              id="example"
              className="table table-striped table-bordered"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Dealer</th>
                  <th>Network</th>
                  <th>Commission</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {ratios.map((user, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{user.dealerId}</td>
                    <td>{user.networkId}</td>
                    <td>{user.commission}</td>
                    <td onClick={()=>{
                      handleOpen()
                      setCom(user.commission)
                      setDealerId(user.dealerId)
                      setNetworkId(user.networkId)
                    } }>Update</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
  );
};

export default DealerCommissions;
