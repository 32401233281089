import React from "react";
import Modal from "./modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import cookie from "react-cookies";
import { UNIFIED_BASE_URL, WEB_BASE_URL } from "./webbase";
class ViewMerchants extends React.Component {
    constructor() {
        super();
        this.state = {
            userEmail: cookie.load("useAdmin"),
            token: cookie.load("tokenAdmin"),
            users: [],
            users2: [],
            selectedDealer: "topupbox",
            activeCount:0,
            inactiveCount:0,
            email:''
        };
        console.log(this.state.token);
        this.onInviteUser = this.onInviteUser.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.onChangeRole = this.onChangeRole.bind(this);
        this.reInvite = this.reInvite.bind(this);
        this.getDealers = this.getDealers.bind(this);
    }

    onInviteUser(event) {
        event.preventDefault();
        if (this.state.formEmail !== "" && this.state.formRole !== "") {
            toast.success("User Role Edited!!!");
        } else {
            toast.error("User Role Edit failed!!!");
        }
    }

    reInvite(email) {
        console.log(email);
        const id = toast.loading("Please wait...");
        axios
            .post(
                UNIFIED_BASE_URL + "/onboarding/re-invite-user?emailAddress=" + email,
                {},
                {
                    headers: {
                        authorization: this.state.token,
                        emailAddress: email,
                    },
                }
            )
            .then((res) => {
                console.log(res);
                if (res.data.status === "2000") {
                    toast.update(id, {
                        render: res.data.description,
                        type: "success",
                        isLoading: false,
                      });
                      window.location.reload()
                } else {
                    toast.update(id, {
                        render:  res.data.description,
                        type: "error",
                        isLoading: false,
                      });
                }

                this.getUsers();
            })
            .catch((err) => {
                console.log(err);
            });
    }
    toggleUser(email) {
        const id = toast.loading("Please wait...");
        console.log(email);
        axios
            .post(
                UNIFIED_BASE_URL + "/dealer/activate-merchant?emailAddress=" + email,
                {},
                {
                    headers: {
                        authorization: this.state.token,
                        emailAddress: email,
                    },
                }
            )
            .then((res) => {
                console.log(res);
                if (res.data.status === "2000") {
                    toast.update(id, {
                        render: res.data.description,
                        type: "success",
                        isLoading: false,
                      });
                   window.location.reload()
                } else {
                    toast.update(id, {
                        render:  res.data.description,
                        type: "error",
                        isLoading: false,
                      });
                }

                this.getUsers();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    //get all users
    getUsers() {
        console.log(this.state.token);
        axios
            .get(UNIFIED_BASE_URL + "/onboarding/stp-users?dealerId="+ this.state.selectedDealer, {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                console.log(res.data);
                if (res.data.status === "2000") {
                    this.setState({
                        users: res.data.data.merchants,
                        activeCount: res.data.data.activeCount,
                        inactiveCount:res.data.data.inactiveCount,
                    });
                } else {
                    toast.error(res.data.description);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    getDealers() {
        axios
            .get(WEB_BASE_URL + "/admin/get-logical-dealer", {
                headers: {
                    authorization: this.state.token,
                },
            })
            .then((res) => {
                console.log(res.data);
                if (res.data.status === "2000") {
                    this.setState({
                        users2: res.data.data.data,
                        selectedDealer: res.data.data.data[0].dealerId
                    });

                    // this.getTransactions();
                } else {
                    toast.error(res.data.description);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    //set state for formRole
    onChangeRole(event) { }

    componentDidMount() {
        this.getUsers();
        this.getDealers()
    }
    componentDidUpdate(prevProps, prevState) {
        // Check if merchantEmail has changed
        if (prevState.selectedDealer !== this.state.selectedDealer  ) {
            this.getUsers();
        }
      }
    render() {
        return (
            <main className="page-content">
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="ps-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 p-0">
                                <li className="breadcrumb-item">
                                    <a href="javascript:;">
                                        <i className="bx bx-home-alt"></i>
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    All Merchants
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="ms-auto">
                  
                    </div>
                    <div className="col-lg-3 col-6 col-md-3">
                                <select
                                    className="form-select"
                                    value={this.state.selectedDealer}
                                    onChange={(event) => {
                                        this.setState({
                                            selectedDealer: event.target.value,
                                        });
                                    }}
                                >
                                    {
                                        this.state.users2.map((user, index) => (
                                            <option key={index} value={user?.dealerId}>{user?.dealerId}</option>
                                        ))
                                    }
                                </select>
                            </div>
                </div>
                <div className="card">
                    <div className="card-body">
                    <div className="row row-cols-1 my-3 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-4">
          <div className="col">
            <div className="card overflow-hidden radius-10">
              <div className="card-body">
                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                  <div className="w-50">
                    <p>Active <br></br> Marchant</p>
                    <h4 className="">{this.state.activeCount}</h4>
                  </div>
                  <div className="w-50">
                    <p className="mb-3 float-end text-success"></p>
                    <div id="chart4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card overflow-hidden radius-10">
              <div className="card-body">
                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                  <div className="w-50">
                    <p>Inactive Marchant</p>
                    <h4 className="">{this.state.inactiveCount}</h4>
                  </div>
                  <div className="w-50">
                    <p className="mb-3 float-end text-success"></p>
                    <div id="chart1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
         
        </div>
                        <div className="d-flex align-items-center">
                        
                            <h5 className="mb-0">All Users Under You</h5>
                            <form className="ms-auto position-relative">
                                <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                                    <i className="bi bi-search"></i>
                                </div>
                                <input
                                    className="form-control ps-5"
                                    type="text"
                                    placeholder="search"
                                />
                            </form>
                        </div>
                        <div className="table-responsive mt-3">
                            <table className="table align-middle">
                                <thead className="table-secondary">
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>States</th>
                                        <th>Role</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.users.map((user, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <div className="d-flex align-items-center gap-3 cursor-pointer">
                                                   
                                                    <div className="">
                                                        <p className="mb-0"></p>
                                                        {user.businessName}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{user?.businessEmail}</td>
                                            <td>{user?.states}</td>
                                            <td>Merchant</td>
                                            <td>
                                                <div className="table-actions d-flex align-items-center gap-3 fs-6">
                                                    <a
                                                        href="javascript:void(0);"
                                                        className="text-success mx-2"
                                                        title="ReInvite Dealer"
                                                        data-bs-original-title="Reprint"
                                                        aria-label="Reprint"
                                                        onClick={(e) => this.reInvite(user?.businessEmail)}
                                                    >
                                                        <i class="bi  bi-arrow-repeat"></i>
                                                    </a>
                                                    {/* <a
                                                        href="javascript:;"
                                                        className="text-danger"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="bottom"
                                                        title="Delete"
                                                    >
                                                        <i className="bi bi-trash-fill"></i>
                                                    </a> */}
                                                    <a
                                                        href="javascript:;"
                                                        className= {user?.status === "active"? `text-success `:`text-danger`}
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="bottom"
                                                        title="Toggle user"
                                                        onClick={(e) => this.toggleUser(user?.businessEmail)}
                                                    >
                                                        <i className="bi bi-toggle-on"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Modal
                                title="Edit User"
                                id="editModal"
                                buttonText="Effect Role Change"
                                onSubmit={this.onInviteUser}
                                onChangeEmail={this.onChangeEmail}
                                onChangeRole={this.onChangeRole}
                                formEmail={"Thomas@gmail.com"}
                                formRole={this.state.formRole}
                            ></Modal>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default ViewMerchants;
