import React from "react";
import Modal from "./modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import cookie from "react-cookies";
import { CLIQ_BASE_URL, WEB_BASE_URL } from "../../webbase";
import Base64Image from "./Base64Image";
class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: cookie.load("user"),
      token: cookie.load("token"),
      role:cookie.load("role"),
      users: [],
    };
    console.log(this.state.token);
    this.onInviteUser = this.onInviteUser.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.onChangeRole = this.onChangeRole.bind(this);
    this.onFundUser = this.onFundUser.bind(this);
  }

  onInviteUser(event) {
    event.preventDefault();
    if (this.state.formEmail !== "" && this.state.formRole !== "") {
      toast.success("User Role Edited!!!");
    } else {
      toast.error("User Role Edit failed!!!");
    }
  }


  onFundUser(amount, user) {
    //event.preventDefault();
    const id = toast.loading("Please wait...");
    const data = {
      "amount": amount,
      "beneficiaryUsername": user
    };
    console.log(data);
    fetch(WEB_BASE_URL + "/admin/direct-funding", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.state.token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res.status === "2000") {
          toast.update(id, {
            render: "Successfully funded User",
            type: "success",
            isLoading: false,
          });
          this.props.navigate("/");
        } else {
          toast.update(id, {
            render: res.description,
            type: "error",
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }


  //get all users
  getUsers() {
    axios
      .get(CLIQ_BASE_URL + "/api/v3/w3/get-all-users", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res.data);
        this.setState({
          users: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //set state for formRole
  onChangeRole(event) { }

  componentDidMount() {
    this.getUsers();
  }

  render() {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  All Users
                </li>
              </ol>
            </nav>
          </div>

        </div>
        <div className="card">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <h5 className="mb-0">All Users in Mobile App and Cliq</h5>
              <form className="ms-auto position-relative">
                <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                  <i className="bi bi-search"></i>
                </div>
                <input
                  className="form-control ps-5"
                  type="text"
                  placeholder="search"
                />
              </form>
            </div>
            <div className="table-responsive mt-3">
              <table className="table align-middle">
                <thead className="table-secondary">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Address</th>
                    <th>Referral code</th>
                    <th>Marchant name</th>
                    {this.state.role === "TOPUPBOX_ADMIN" ?
                      <th>BVN</th>
                    :null}
                    
                    <th>Image</th>
                    <th>Current Balance</th>
                    <th>Bvn status</th>
                    <th>PIN status</th>
                    <th>Date created</th>
                    <th>Last Login</th>
                    <th>Status</th>
                    {this.state.role === "TOPUPBOX_ADMIN" ?
                      <th>Actions</th>
                    :null}
                   
                  </tr>
                </thead>
                <tbody>
                  {this.state.users.map((user, index) => {
                    const transactionDate = new Date(user?.lastLogin);
                      const formattedDate = transactionDate.toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      });

                      const formattedTime = transactionDate.toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      });
                    return (
                    <>
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <div className="d-flex align-items-center gap-3 cursor-pointer">
                            <img
                              src="assets/images/dummy.png"
                              className="rounded-circle"
                              width="44"
                              height="44"
                              alt=""
                            />
                            <div className="">
                              <p className="mb-0">
                                {user?.firstName} {user?.middleName} {user?.lastName}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>{user?.emailAddress}</td>
                        <td>{user?.phoneNumber}</td>
                        <td width={50}>{user?.contactAddress}</td>
                        <td>{user?.referralCode}</td>
                        <td>{user?.merchantName}</td>
                        {this.state.role === "TOPUPBOX_ADMIN" ?
                          <td>{user?.bvn}</td>
                         :null}
                        
                        <td>{user?.base64Image === ""?"":Base64Image(user?.base64Image)}</td>
                        <td> {formatter.format(user?.currentBal)}{}</td>
                        <td>{user?.bvnStatus ?' Active':'Pending'}</td>
                        <td>{user?.pinStatus ?' Active':'Pending'}</td>
                        <td>{user?.dateCreated.split("T")[0]}</td>
                        <td>{formattedDate} {formattedTime}</td>
                        <td>{user?.status}</td>
                        {this.state.role === "TOPUPBOX_ADMIN" ?
                          <td>
                          <div className="table-actions d-flex align-items-center gap-3 fs-6">
                            {/* <a
                              href="javascript:;"
                              className="text-success"
                              data-bs-toggle="modal"
                              data-bs-target={"#editModal" + index}
                              title="Add Funds"
                            >
                              <i className="bi bi-piggy-bank-fill"></i>
                            </a> */}
                            <a
                              href="javascript:;"
                            
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              title="Deactivate"
                            >
                            {user?.blocked? <i className="bi bi-toggle2-on"></i>: <i className="bi bi-toggle2-off"></i>} 
                             
                            </a>
                          </div>
                        </td>
                         :null}
                       
                      </tr>
                      <Modal
                        title="Fund User"
                        id={"editModal" + index}
                        buttonText="Add funds"
                        onSubmit={this.onFundUser}
                        onChangeEmail={this.onChangeEmail}
                        onChangeRole={this.onChangeRole}
                        formEmail={user?.emailAddress}
                      ></Modal>
                    </>
                  )})}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Users;
