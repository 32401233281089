import React from "react";
import Modal from "./modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import cookie from "react-cookies";
import { WEB_BASE_URL } from "./webbase";
class Users extends React.Component {
  constructor() {
    super();
    this.state = {
      userEmail: cookie.load("user"),
      role:cookie.load("role"),
      token: cookie.load("token"),
      users: [],
      email:''
    };
    console.log(this.state.token);
    this.onInviteUser = this.onInviteUser.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.onChangeRole = this.onChangeRole.bind(this);
  }

  onInviteUser(event) {
    event.preventDefault();
    if (this.state.formEmail !== "" && this.state.formRole !== "") {
      toast.success("User Role Edited!!!");
    } else {
      toast.error("User Role Edit failed!!!");
    }
  }

  //get all users
  getUsers() {
    axios
      .get(WEB_BASE_URL + "/admin/get-all-users", {
        headers: {
          authorization: this.state.token,
        },
      })

      .then((res) => {
        console.log(res.data);
        this.setState({
          users: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //set state for formRole
  onChangeRole(event) { }

  componentDidMount() {
    this.getUsers();
  }

  render() {
    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  All Admin Users
                </li>
              </ol>
            </nav>
          </div>
          <div className="ms-auto">
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#addModal"
              >
                Invite New User
              </button>
              <button
                type="button"
                className="btn btn-primary split-bg-primary dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown"
              >
                {" "}
                <span className="visually-hidden">Toggle Dropdown</span>
              </button>
              <Modal
                title="Invite New User"
                id="addModal"
                buttonText="Add User"
                onSubmit={this.onInviteUser}
                onChangeEmail={this.onChangeEmail}
                onChangeRole={this.onChangeRole}
                formEmail={this.state.formEmail}
                formRole={this.state.formRole}
              ></Modal>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <h5 className="mb-0">All Admin Users</h5>
              <form className="ms-auto position-relative">
                <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                  <i className="bi bi-search"></i>
                </div>
                <input
                  className="form-control ps-5"
                  type="text"
                  placeholder="search"
                />
              </form>
            </div>
            <div className="table-responsive mt-3">
              <table className="table align-middle">
                <thead className="table-secondary">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    {this.state.role === "TOPUPBOX_ADMIN"? <th>Actions</th>:null }
                   
                  </tr>
                </thead>
                <tbody>
                  {this.state.users.map((user, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <div className="d-flex align-items-center gap-3 cursor-pointer">
                          
                          <div className="">
                            <p className="mb-0">
                              {user.firstName} {user.lastName}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>{user.emailAddress}</td>
                      <td>{user.userRole}</td>
                      {this.state.role === "TOPUPBOX_ADMIN"? 
                        <td>
                        <div className="table-actions d-flex align-items-center gap-3 fs-6">
                          <a
                            href="javascript:;"
                            className="text-warning"
                            data-bs-toggle="modal"
                            data-bs-target="#editModal"
                            title="Edit"
                            onClick={()=>{
                              this.setState({
                                 email: user.emailAddress,
                                });
                            }}
                          >
                            <i className="bi bi-pencil-fill"></i>
                          </a>
                          <a
                            href="javascript:;"
                            className="text-danger"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title="deactivate"
                          >
                            <i className="bi bi-toggle-on"></i>
                          </a>
                        </div>
                      </td>
                      :null }
                     
                    </tr>
                  ))}
                </tbody>
              </table>
              <Modal
                title="Edit User"
                id="editModal"
                buttonText="Effect Role Change"
                onSubmit={this.onInviteUser}
                onChangeEmail={this.onChangeEmail}
                onChangeRole={this.onChangeRole}
                formEmail={this.state.email}
                formRole={this.state.formRole}
              ></Modal>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Users;
