import React from "react";
import DatePicker from "react-datepicker";
import { toast, ToastContainer } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { UNIFIED_BASE_URL, WEB_BASE_URL } from "./webbase";
import axios from "axios";
import cookie from "react-cookies";
import { CSVLink } from "react-csv";

class Transaction extends React.Component {
  constructor() {
    super();
    this.state = {
      someKey: "someValue",
      startDate: new Date(),
      endDate: new Date(),
      token: cookie.load("tokenAdmin"),
      fromDate: this.makeShortDate(new Date()),
      toDate: this.makeShortDate(new Date()),
      transactions: [],
      dataByDate: {},
      currentPage: 1,
      dealerId: "mobile@topupcliq.com",
      product: "Enterprise",
      dealers: [],
      salesValue: 0,
      totalGenerated: 0,
      salesUnits: 0
    };
    //binding this to event-handler functions
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.getEnterpriseReport = this.getEnterpriseReport.bind(this);
    this.getLogicalReport = this.getLogicalReport.bind(this);
    this.getUnicardReport = this.getUnicardReport.bind(this);
    this.reduceTransactions = this.reduceTransactions.bind(this);
    this.makeShortDate = this.makeShortDate.bind(this);
    this.getDealersList = this.getDealersList.bind(this);
  }

  makeShortDate(datew) {
    datew = new Date(datew);
    let yy = datew.getFullYear();
    let mm = datew.getMonth();
    let dd = datew.getDate();
    let shortDate = `${yy}/${mm < 10 ? 0 : ""}${mm + 1}/${dd < 10 ? 0 : ""
      }${dd}`;
    return shortDate;
  }
  setStartDate(date) {
    this.setState({
      startDate: date,
    });
  }

  setEndDate(date) {
    this.setState({
      endDate: date,
    });
  }


  reduceTransactions(transactions) {
    const dataByDate = transactions.reduce((accumulator, transaction) => {
      const date = transaction.requestDate.split("T")[0];
      const amount = transaction.amount;
      if (!accumulator[date]) {
        accumulator[date] = 0;
      }
      accumulator[date] += amount;
      return accumulator;
    }, {});
    this.setState({ dataByDate });
  }


  //function to call custom report API for Enterprise
  getEnterpriseReport() {
    let api = "";
   
    api = "/merchant/unicard-custom-report";

    axios
      .get(UNIFIED_BASE_URL + api + "?startDate=" +
        this.makeShortDate(this.state.startDate).split('/').join('-') +
        "&endDate=" +
        this.makeShortDate(this.state.endDate).split('/').join('-') + "&username=" + this.state.dealerId, {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res.data)
        if (res.data.status === "2000") {
          this.setState({ transactions: res.data.report.reverse(),
            salesValue:  res.data.data.salesValue,
            totalGenerated:res.data.data["total generated"],
            salesUnits:res.data.data.salesUnits,
           });
          this.reduceTransactions(res.data.report.reverse());
        }
        else {
          // const trans = res.data.data.data.reverse();
       
        }
      })
      .catch((err) => {
        // toast.error("Error fetching transactions");
        console.log(err)
      });
  }

  componentDidMount() {
    this.getEnterpriseReport()
    this.getDealersList();
  }

  getDealersList() {
    axios
      .post(UNIFIED_BASE_URL + "/onboarding/all-merchants", {},{
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res.data);
        this.setState({
          dealers: res.data.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //function to call custom report API for Logical
  getLogicalReport() {
  }

  //function to call custom report API for Unicard
  getUnicardReport() {
  }
  handleSelectChange = (event) => {
    
    const selectedDealerId = event.target.value;
    this.setState({ dealerId: selectedDealerId });
    this.props.onChangeRole(selectedDealerId);
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.dealerId !== this.state.dealerId || prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate  ) {
      this.getEnterpriseReport();
    }
  }
  render() {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });

    const pageSize = 50;
    let totalPages = 0;
    if (this.state.transactions.length !== 0) {
      totalPages = Math.ceil(this.state.transactions.length / pageSize);
    }

    const handlePageChange = (page) => {
      this.setState({ currentPage: page });
    }

    let transactionsToDisplay = this.state.transactions.slice((this.state.currentPage - 1) * pageSize, this.state.currentPage * pageSize);
    //console.log(transactionsToDisplay)
    return (
      <main className="page-content">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                Unicard  Transactions
                </li>
              </ol>
            </nav>
          </div>
          <div className="ms-auto">
            <div className="btn-group">
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <form className="row">
              <div className="row">
                
                <div className="col-3">
                  <label className="form-label">Dealer:</label>
                  <select
                    className="form-select "
                    aria-label="Default select example"
                    value={this.state.dealerId}
                    onChange={this.handleSelectChange}
                  >
                    <option selected="">Select Dealer</option>
                     {
                       this.state.dealers.map((dealer, index) => (
                        <option value={dealer.businessEmail}>{dealer.businessName}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="col-3">
                  <p className="form-label">From:</p>
                  <DatePicker
                    selected={this.state.startDate}
                    onSelect={(date) =>{ 
                      this.setStartDate(date)
                      
                      }}
                    onChange={(date) => {
                      this.setStartDate(date)
                      }}
                  />
                </div>
                <div className="col-3">
                  <p className="form-label">To:</p>
                  <DatePicker
                    selected={this.state.endDate}
                    onSelect={(date) => {
                      this.setEndDate(date)
                      this.getEnterpriseReport()
                      }}
                    onChange={(date) => {
                      this.setEndDate(date)
                      this.getEnterpriseReport()
                      }}
                  />
                </div>

              </div>
              <div className="row mt-2">
                <div className="col-3">
                  <label className="form-label">Transaction Status</label>
                  <select
                    className="form-select "
                    aria-label="Default select example"
                    value={this.props.formRole}
                    onChange={(event) => this.props.onChangeRole(event)}
                  >
                    <option selected=""> Select Status</option>
                    <option value="1">All</option>
                    <option value="2">Success</option>
                    <option value="3">Failed</option>
                  </select>
                </div>
                {/* <div className="col-3">
                  <CSVLink
                    data={this.state.transactions}
                    filename={"Topupbox Admin Report.csv"}
                    target="_blank"
                  >
                    <button
                      className="btn btn-primary d-flex"
                      type="button"
                      style={{
                        width: "100%",
                        marginTop: "26px"
                      }}
                    >
                      <div>
                        <i class="bi bi-cloud-arrow-down-fill"></i>
                      </div>
                      <div className="mx-1">Export report as CSV</div>
                    </button>
                  </CSVLink>
                </div> */}
                <div className="col-3">
                  <CSVLink
                    data={this.state.transactions}
                    filename={"Topupbox Admin Report.csv"}
                    target="_blank"
                  >
                    <button
                      className="btn btn-primary d-flex"
                      type="button"
                      style={{
                        width: "100%",
                        marginTop: "26px"
                      }}
                    >
                      <div>
                        <i class="bi bi-cloud-arrow-down-fill"></i>
                      </div>
                      <div className="mx-1">Export report as CSV</div>
                    </button>
                  </CSVLink>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="card">
          <div className="card-header py-3">
            <div className="row g-3">
              <div className="col-lg-3 col-md-6 me-auto">
                <div className="ms-auto position-relative">
                  <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                    <i className="bi bi-search"></i>
                  </div>
                  <input
                    className="form-control ps-5"
                    type="text"
                    placeholder="Search Payment"
                  />
                </div>
              </div>

             
            </div>
            <div className="row my-4 row-cols-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-4">
          <div className="col">
            <div className="card overflow-hidden radius-10">
              <div className="card-body">
                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                  <div className="w-50">
                    <p>Sales <br></br> value</p>
                    <h4 className=""> {this.state.salesValue}</h4>
                  </div>
                  <div className="w-50">
                    <p className="mb-3 float-end text-success"></p>
                    <div id="chart4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card overflow-hidden radius-10">
              <div className="card-body">
                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                  <div className="w-50">
                    <p>Total generated:</p>
                    <h4 className="">{this.state.totalGenerated}</h4>
                  </div>
                  <div className="w-50">
                    <p className="mb-3 float-end text-success"></p>
                    <div id="chart1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card overflow-hidden radius-10">
              <div className="card-body">
                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                  <div className="w-50">
                    <p>Sales <br></br> unit</p>
                    <h4 className="">{this.state.salesUnits}</h4>
                  </div>
                  <div className="w-50">
                    <p className="mb-3 float-end text-success"></p>
                    <div id="chart2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
                          </div>
                        
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                id="example"
                class="table table-striped table-bordered"
                style={{ width: "100%" }}
              >
                <thead className="table-light">
                  <tr>
                    <th>ID</th>
                    <th>Value</th>
                    <th>Unit Price</th>
                    <th>User id</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {transactionsToDisplay.length !== 0 ? (
                    transactionsToDisplay.map((voucher, index) => (
                      <tr key={index}>
                        <td>{voucher.id}</td>
                        <td>
                          <div className="d-flex align-items-center gap-3">
                            <div className="product-info">
                              <h6 className="product-name mb-1">
                                {voucher.value}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td>
                        {formatter.format(voucher.unitPrice)}
                        </td>
                        <td>{voucher?.userId}</td>
                        <td>
                          {voucher.dateCreated !== undefined
                            ? voucher.dateCreated.split("T")[0]
                            : 0}
                        </td>
                        <td>{voucher.used?'Used':"Unused"}</td>
                      </tr>
                    ))
                  ) : (
                    <tr colSpan="7">
                      <td>No Transaction Record.</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div style={{
                overflowX: "scroll",
              }}>
                <nav class="float-end mt-3">
                  <ul class="pagination">
                    {Array.from(Array(totalPages), (e, i) => {
                      let pageNumber = i + 1;
                      return (
                        <li class={"page-item " + this.state.currentPage == pageNumber && "active"}>
                          <a class="page-link" href="/2"
                            style={{
                              backgroundColor: this.state.currentPage == pageNumber ? "#0d6efd" : "",
                              color: this.state.currentPage == pageNumber ? "#fff" : ""
                            }}
                            onClick={
                              (e) => {
                                e.preventDefault();
                                this.setState({
                                  currentPage: pageNumber
                                })
                              }
                            }
                          >
                            {pageNumber}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </main >
    );
  }
}

export default Transaction;
