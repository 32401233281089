import axios from "axios";
import React from "react";
import DatePicker from "react-datepicker";
import cookie from "react-cookies";
import "react-datepicker/dist/react-datepicker.css";
import { UNIFIED_BASE_URL, WEB_BASE_URL } from "../../webbase";
import queryString from "query-string";
import { toast, ToastContainer } from "react-toastify";

class Invoices extends React.Component {
  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      dealer: query?.dealer?.replace(/ /g, "+") ? query.dealer.replace(/ /g, "+") : null,
      startDate: new Date(),
      endDate: new Date(),
      transactions: [],
      fundingHistory: [],
      token: cookie.load("token"),
    };
    //binding this to event-handler functions
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.getTransactions = this.getTransactions.bind(this);
  }
  setStartDate(date) {
    this.setState({
      startDate: date,
    });
  }

  //write a funtion to get the transactions
  getTransactions() {
    axios
      .get(UNIFIED_BASE_URL + "/dealer/fund-wallet-request", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res.data);
        //check if data isnt null
        if (res.data.data !== null) {
          this.setState({
            transactions: res.data.data,
          });
        }
        else {
          this.setState({
            transactions: [],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getFundingDirect(amount, name, email) {
    axios
      .post(UNIFIED_BASE_URL + "/manage/fund-vas-direct",{
        "amount": amount,
        "merchantEmail": email,
        "narration": `vasfunding/${name}/${amount}`,
        "reciepientAccountName": name,
        "referenceNumber": "1234567"
      }, {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res.data);
        //check if data isnt null
        if (res.data.data !== null) {
         toast.success(res.data.description)
         window.location.reload()
        }
        else {
          toast.error(res.data.description)

        }
      })
      .catch((err) => {
        console.log(err);
      });
  }


  setEndDate(date) {
    this.setState({
      endDate: date,
    });
  }

  componentDidMount() {
    this.getTransactions();
  }

  render() {
    return (
      <main className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="ps-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 p-0">
                <li className="breadcrumb-item">
                  <a href="javascript:;">
                    <i className="bx bx-home-alt"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Transactions
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <ToastContainer/>
        {/* <div className="card">
          <div className="card-body">
            <form className="row">
              <div className="row">
                <div className="col-4">
                  <label className="form-label">From:</label>
                  <DatePicker
                    selected={this.state.startDate}
                    onSelect={(date) => this.setStartDate(date)}
                    onChange={(date) => this.setStartDate(date)}
                  />
                </div>
                <div className="col-4">
                  <label className="form-label">To:</label>
                  <DatePicker
                    selected={this.state.startDate}
                    onSelect={(date) => this.setEndDate(date)}
                    onChange={(date) => this.setEndDate(date)}
                  />
                </div>
                <div className="col-4">
                  <label className="form-label">Transaction Type</label>
                  <select
                    className="form-select "
                    aria-label="Default select example"
                    value={this.props.formRole}
                    onChange={(event) => this.props.onChangeRole(event)}
                  >
                    <option selected=""> Select Status</option>
                    <option value="1">All</option>
                    <option value="2">Pending</option>
                    <option value="3">Approved</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <button
                    style={{ marginTop: 29 }}
                    className="btn btn-primary d-flex"
                  >
                    <div>
                      <i class="bi bi-cloud-arrow-down-fill"></i>
                    </div>
                    <div className="mx-3">Download Report</div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div> */}

        <div className="card">
          <div className="card-header py-3">
            <div className="row g-3">
              <div className="col-lg-3 col-md-6 me-auto">
                <div className="ms-auto position-relative">
                  <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                    <i className="bi bi-search"></i>
                  </div>
                  <input
                    className="form-control ps-5"
                    type="text"
                    placeholder="Search Payment"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                id="example"
                class="table table-striped table-bordered"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Dealer name</th>
                    <th>Amount</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.transactions.map((transaction, index) => (
                      <tr key={index}>
                        <td>{transaction?.id}</td>
                        <td>{transaction?.dealer}</td>
                        <td>NGN {transaction?.amount}</td>
                        <td>{transaction?.createdDate.split("T")[0]}</td>
                        <td>{transaction?.status}</td>
                        <td onClick={this.getFundingDirect(transaction?.amount,transaction?.name,transaction?.email)}> Approve</td>
                      </tr>
                    ))
                  }
                  <tr></tr>
                </tbody>
              </table>
            </div>
            {/* <nav class="float-end mt-3">
              <ul class="pagination">
                <li class="page-item disabled">
                  <a class="page-link" href="#">
                    Previous
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="#">
                    1
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    2
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    3
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    Next
                  </a>
                </li>
              </ul>
            </nav> */}
          </div>
        </div>
      </main>
    );
  }

}

export default Invoices;
