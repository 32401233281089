import React from "react";
import Header from "./header";
import Main from "./main";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SideBar from "./SideBar";
import cookie from "react-cookies";
import Logical from "./Logical/logical";
import SignIn from "./signIn";
import Transaction from "./transaction";
import Mobile from "./AppMobile/mobileapp";
import Enterprise from "./Enterprise/enterprise";
import Vas from "./VAS/vas";
import CreateUser from "./CreateUser";
import Users from "./users";
import Sme from "./SME";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: cookie.load("userAdmin"),
      token: "",
    };
  }

  componentDidMount() {
    const user = cookie.load("userAdmin");
    const token = cookie.load("tokenAdmin");

    if (!user || !token) {
      console.log(user);
      this.setState({
        user: undefined,
      });
      return;
    } else {
      console.log(user);
    }
  }

  render() {
    return this.state.user === undefined ? (
      <SignIn {...this.props} />
    ) : (
      <div>
        <Header {...this.props}></Header>
        <SideBar {...this.props}></SideBar>
        <Switch>
          <Route
            exact
            path="/"
            key={1}
            render={(routerProps) => <Main {...this.props}></Main>}
          />
          <Route
            exact
            path="/invite-users"
            key={1}
            render={(routerProps) => <CreateUser {...this.props} ></CreateUser>}
          />
          <Route
            exact
            path="/view-users"
            key={1}
            render={(routerProps) => <Users {...this.props}></Users>}
          />
          <Route
            path="/logical"
            key={2}
            render={(routerProps) => <Logical {...this.props}></Logical>}
          />

          <Route
            path="/mobile"
            key={2}
            render={(routerProps) => <Mobile {...this.props}></Mobile>}
          />

          <Route
            path="/transactions"
            key={3}
            render={(routerProps) => <Transaction {...this.props}></Transaction>}
          />

          <Route
            path="/enterprise"
            key={4}
            render={(routerProps) => <Enterprise {...this.props}></Enterprise>}
          />

          <Route
            path="/bills"
            key={30}
            render={(routerProps) => <Vas {...this.props}></Vas>}
          />
           <Route
            path="/ussd/transactions"
            key={130}
            render={(routerProps) => <Sme {...this.props}></Sme>}
          />
        </Switch>
      </div >
    );
  }
}

export default Dashboard;
