import React from "react";
import axios from "axios";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import Voucheritem from "./voucheritem";
import { WEB_BASE_URL,UNIFIED_BASE_URL, CLIQ_BASE_URL} from "./webbase";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarElement,
  ArcElement,
} from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { BlockLoading } from 'react-loadingg';
class Main extends React.Component {
  constructor() {
    super();
    this.state = {
      token: cookie.load("token"),
      businessName: cookie.load("businessName"),
      vouchers: [],
      startDate: new Date(),
      endDate: new Date(),
      users: [],
      transactions: [],
      totalTransactionSum:0,
      totalTransactionSum2:0,
      totalTransactionSuccessful:0,
      totalTransactionFailed:0,
      totalNoOfUser: 0,
      totalTransaction:0
    };
    this.getVouchers = this.getVouchers.bind(this);
    this.getEnterpriseReport = this.getEnterpriseReport.bind(this);
    this.getUsers = this.getUsers.bind(this);

    ChartJS.register(
      ArcElement,
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      BarElement,
      Title,
      Tooltip,
      Legend,
      Filler,
    );
  }

  makeShortDate(datew) {
    datew = new Date(datew);
    let yy = datew.getFullYear();
    let mm = datew.getMonth();
    let dd = datew.getDate();
    let shortDate = `${yy}/${mm < 10 ? 0 : ""}${mm + 1}/${dd < 10 ? 0 : ""
      }${dd}`;
    return shortDate;
  }

  //get all users
  getUsers() {
    axios
    .get(WEB_BASE_URL + "/admin/get-logical-dealer",  {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res.data);
        this.setState({
       users: res.data.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getVouchers = () => {
    const id = toast.loading("Please wait...");
    axios
      .get(WEB_BASE_URL + "/api/v2/u1/pin-manager", {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        res.data.data.reverse();
        this.setState({ vouchers: res.data.data });
        toast.dismiss(id);
      })
      .catch((err) => {
        toast.update(id, {
          render: "" + err,
          type: "error",
          isLoading: false,
        });
      });
  };
  getEnterpriseReport() {
    let api = "";
    const today = new Date();
    let datee = this.makeShortDate(this.state.endDate);
    let yesterday = "";
    yesterday = this.makeShortDate(this.state.startDate)

    api = "/dealer/dealer-custom-report";

    axios
      .get(UNIFIED_BASE_URL + api + "?startDate=" +
        yesterday.split('/').join('-') +
        "&endDate=" +
        datee.split('/').join('-') + "&offSet=0&page=1000&network=&dealerId=topupbox&dealerEmail=dealer@topupbox.com" , {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res.data)
        if (res.data.description === "No data available") {
        }
        else {
          // const trans = res.data.data.data.reverse();
          this.setState({  
            transactions: res.data.data,
            totalTransactionSum: res.data.summary.totalTransactionCount,
            totalTransactionSum2: res.data.summary.totalTransactionSum,
            totalTransactionSuccessful:res.data.summary.totalTransactionSuccessful,
            totalTransactionFailed: res.data.summary.totalTransactionFailed,} );
          
        }
      })
      .catch((err) => {
        toast.error("Error fetching transactions");
        console.log(err)
      });
      axios
      .get(CLIQ_BASE_URL + '/api/v3/w3/sumarry-trans-report?merchantEmail=mobile@topupcliq.com&startDate=' +
        yesterday.split('/').join('-') +
        "&endDate=" +
        datee.split('/').join('-') , {
        headers: {
          authorization: this.state.token,
        },
      })
      .then((res) => {
        console.log(res.data)
        if (res.data.description === "No data available") {
        }
        else {
          // const trans = res.data.data.data.reverse();
          this.setState({  
            totalNoOfUser:res.data.data.userData.activeUsersCount + res.data.data.userData.inactiveUsersCount,
            totalTransaction:res.data.data.transactionsLog.totalTransactionCount
          } );
          
        }
      })
      .catch((err) => {
        toast.error("Error fetching transactions");
        console.log(err)
      });
  }
  componentDidMount() {
   this.getEnterpriseReport()
   this.getUsers();

  }

  render() {
  //   var formatter = new Intl.NumberFormat("en-US", {
  //     style: "currency",
  //     currency: "NGN",
  // });
    const dates = [];
    const transactions = [];
    for (let i = 6; i >= 0; i--) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      dates.push(date.toLocaleDateString());

      const transactionCount = Math.floor(Math.random() * 100); // Random transaction count
      transactions.push(transactionCount);
    }

    //const labels = Object.keys(this.state.dataByDate);
    const labels = ["Total transaction for today on enterprise"];

    const options = {
        responsive: true,
        plugins: [{
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart',
            },
        },
        {
            beforeDraw: (chart) => {
                if (this.state.isLoading) {
                    const canvas = chart.canvas;
                    const ctx = canvas.getContext('2d');
                    const centerX = canvas.width / 2;
                    const centerY = canvas.height / 2;

                    ctx.save();
                    ctx.beginPath();
                    ctx.translate(centerX, centerY);
                    BlockLoading.render({
                        size: 50,
                        color: '#263B95',
                        speed: 1,
                        duration: 2000
                    }, ctx);
                    ctx.restore();
                }
            }
        }
        ],
        scales: {
            x: {
                grid: {
                    display: false,
                },
                scaleLabel: {
                    display: true,
                    labelString: "Transaction Type"
                },
            },
            y: {
                grid: {
                    display: false,
                },
                scaleLabel: {
                    display: true,
                    labelString: "Number of transactions"
                },
            }
        }
    };
    const data = {
        labels,
        datasets: [
            {
                label: "All transactions",
                data: [this.state.totalTransactionSuccessful + this.state.totalTransactionFailed],
                backgroundColor: '#263B95',
                borderColor: '#263B95',
                borderRadius: 14,
                borderWidth: 2,
                fill: true, // Add color to the area underneath the line
            },
            {
                label: "Successful transactions",
                data: [this.state.totalTransactionSuccessful],
                backgroundColor: '#76c48b',
                borderColor: '#76c48b',
                borderRadius: 14,
                borderWidth: 2,
                fill: true, // Add color to the area underneath the line
            },
            {
                label: "Failed transactions",
                data:[this.state.totalTransactionFailed],
                backgroundColor: '#e0365e',
                borderColor: '#e0365e',
                borderRadius: 14,
                borderWidth: 2,
                fill: true, // Add color to the area underneath the line
            },
        ],
    };

    const pieData = {
      labels: dates,
      datasets: [
        {
          label: 'Dealer Ratio',
          data: transactions,
          backgroundColor: ['#50aadf', '#263B95', "#E0115F", '#7925c6'],
          borderColor: '#ffffff',
          borderWidth: 2,
          fill: true, // Add color to the area underneath the line
        },
      ],
    };
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
  });
    return (
      <div className="page-content">
        <div className="row">
          <div className="col-md-9">
            <div className="card overflow-hidden radius-10">
              <div className="card-body align-items-center flex justify-content-between" style={{
                minHeight: "30vh",
                maxHeight: "40vh",
                paddingLeft: "7%"
              }}>
                {this.state.isLoading && <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><BlockLoading color="#263B95" /></div>} 
                <Bar options={options} data={data} />
              </div>
            </div>
          </div>
          {/* <div className="col-lg-3 col-sm-6 col-rows-3">
            <div className="row">
              <div className="card overflow-hidden radius-10">
                <div className="card-body">
                  <div className=" align-items-stretch justify-content-between overflow-hidden pb-1">
                    <Pie data={pieData} />
                  </div>
                </div>
              </div>
            </div>

          </div> */}
          <div className="col-sm-3">
          <div className="row  g-3 ">
                            <div className="col-md-5  card">
                                <div className=" overflow-hidden radius-10">
                                    <div className="  card-body">
                                        <div className=" align-items-stretch  h-100 justify-content-between overflow-hidden pb-1">
                                            <div className="">
                                               {/* <AccountBalanceWalletIcon size={'xl'}/> */}
                                                <div className="mb-1 mt-4">Total successful transaction</div>

                                                <h5 className="" style={{
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                }}>
                                                   {this.state.totalTransactionSuccessful}
                                                </h5>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-1 "></div>
                            <div className="col-md-5  card">
                                <div className=" overflow-hidden radius-10">
                                    <div className="card-body">
                                        <div className="align-items-stretch  h-100 justify-content-between overflow-hidden">
                                            <div className="">
                                            {/* <PaymentsIcon/> */}
                                                <div className="mb-1 mt-4">Total unsuccessful transaction</div>
                                                <h5 className="" style={{
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                }}>{ this.state.totalTransactionFailed}</h5>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>

                            <div className="row   ">
                            <div className="col-md-5  card ">
                                <div className=" overflow-hidden radius-10">
                                    <div className="card-body">
                                        <div className="align-items-stretch justify-content-between overflow-hidden">
                                            <div className=" mb-2">
                                            {/* <img
                                                    src="assets/images/incoming.png"
                                                    style={{
                                                        height: "28px",
                                                        width: "auto",
                                                        borderRadius: "32px",
                                                        marginBottom: "5px"
                                                    }}
                                                    className="mb-4"
                                                    alt=""
                                                /> */}
                                                <div className="mb-1">Total transactions</div>
                                                <h5 className="" style={{
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                }}>{this.state.totalTransactionSum}</h5>
                                               
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        <div className="col-md-1 "></div>
                            <div className="col-md-5 card">
                                <div className=" overflow-hidden radius-10">
                                    <div className="card-body">
                                        <div className="align-items-stretch justify-content-between overflow-hidden">
                                            <div className="">
                                                {/* <img
                                                    src="assets/images/valid.png"
                                                    style={{
                                                        height: "28px",
                                                        width: "auto",
                                                        borderRadius: "32px",
                                                        marginBottom: "5px"
                                                    }}
                                                    alt=""
                                                    className="mb-4"
                                                /> */}
                                                <div className="mb-1">Total Transaction Value</div>
                                                <h5 className="" style={{
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                }}>{this.state.totalTransactionSum2}</h5>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-4">
          <div className="col">
            <div className="card overflow-hidden radius-10">
              <div className="card-body">
                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                  <div className="w-50">
                    <p>Dealers on Platform</p>
                    <h4 className="">{this.state.users.length}</h4>
                  </div>
                  <div className="w-50">
                    <p className="mb-3 float-end text-success"></p>
                    <div id="chart4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card overflow-hidden radius-10">
              <div className="card-body">
                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                  <div className="w-50">
                    <p>Logical Transactions</p>
                    <h4 className="">0</h4>
                  </div>
                  <div className="w-50">
                    <p className="mb-3 float-end text-success"></p>
                    <div id="chart1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card overflow-hidden radius-10">
              <div className="card-body">
                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                  <div className="w-50">
                    <p>Topupcliq App Users</p>
                    <h4 className="">{this.state.totalNoOfUser}</h4>
                  </div>
                  <div className="w-50">
                    <p className="mb-3 float-end text-success"></p>
                    <div id="chart2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card overflow-hidden radius-10">
              <div className="card-body">
                <div className="d-flex align-items-stretch justify-content-between overflow-hidden">
                  <div className="w-50">
                    <p>TopupCliq Transactions</p>
                    <h4 className="">{this.state.totalTransaction}</h4>
                  </div>
                  <div className="w-50">
                    <p className="mb-3 float-end text-success"></p>
                    <div id="chart3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
                        <div class="table-responsive">
                            <table className="table align-middle mb-0">
                                <thead className="table-light">
                                    <tr>
                                        <th>Merchant</th>
                                        <th>Network</th>
                                        <th>Beneficiary</th>
                                        <th>Amount</th>
                                        <th>Type</th>
                                        <th>Date</th>
                                        <th>Current Balance</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.transactions.length !== 0 ? (
                                      this.state.transactions.slice(0, 10).map((voucher, index) => (
                                            <tr key={index}>

                                                <td>
                                                    <div className="d-flex align-items-center gap-3">
                                                        <div className="product-info">
                                                            <h6 className="product-name mb-1">
                                                                {voucher.accountId}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{voucher?.networkId}</td>
                                                <td>{voucher?.beneficiary}</td>
                                                <td>{formatter.format(voucher.amount)}</td>
                                                <td>{voucher.requestType}</td>
                                                <td>
                                                    {voucher.requestDate !== undefined
                                                        ? voucher.requestDate.split("T")[0]
                                                        : 0}
                                                </td>
                                                <td>{formatter.format(voucher.newBalance)}</td>
                                                <td>{voucher.statusMessage}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr colSpan="7">
                                            <td>No Transaction Record.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
      </div >
    );
  }
}

export default Main;
