import React, { useState } from 'react';
import { ENTERPRISE_URL } from '../../webbase';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import cookie from "react-cookies";
const SetCommissionModal = ({ closeModal, commission,dealerId,networkId }) => {
    const [com, setCom] = useState(commission)
    const [dealer, setDealer] = useState(dealerId)
    const [network, setNework] = useState(networkId)
    const token = cookie.load("tokenAdmin")
    const onSubmit = (event) => {
        event.preventDefault();
        const id = toast.loading("Please wait...");
        const data = {
            "commission":com,
            "dealerId": dealer,
            "networkId": network
        };
    
        axios
          .post(ENTERPRISE_URL + "/manage/edit-commission-config", data, {
            headers: {
              authorization: token,
            },
          })
          .then((res) => {
            console.log(res.data);
            if (res.data.status === "2000") {
                toast.update(id, {
                    render: res.data.description,
                    type: "success",
                    isLoading: false,
                  });
                  setTimeout(() => {
                    window.location.reload()
                  }, 2000);
            
            } else {
                toast.update(id, {
                    render: res.data.description,
                    type: "error",
                    isLoading: false,
                  });
                  setTimeout(() => {
                    toast.dismiss(id);
                  }, 2000);
                
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };
  return (
    <div style={modalStyles.overlay}>
      <div style={modalStyles.modal}>
      <div style={modalStyles.content}>
      <h5 style={modalStyles.paragraph} onClick={closeModal} className='mb-3'>X</h5>
      </div>
      
        <h2>Set commission</h2>
        <div class="col-12 mb-3">
                <label class="form-label">Commission</label>
                <input
                  type="text"
                  name="Commission"
                  class="form-control"
                  style={{ borderRadius: "5px" }}
                  value={com}
                  onChange={(event) => {
                    setCom(event.target.value)
                  }}
                  required
                />
        </div>
        <div class="col-12 mb-3">
                <label class="form-label">Dealer Id</label>
                <input
                  type="text"
                  name="Dealer Id"
                  class="form-control"
                  style={{ borderRadius: "5px" }}
                  value={dealer}
                  onChange={(event) => {
                    setDealer(event.target.value)
                  }}
                  required
                />
        </div>
        <div class="col-12 mb-3">
                <label class="form-label">Network Id</label>
                <input
                  type="text"
                  name="Network Id"
                  class="form-control"
                  style={{ borderRadius: "5px" }}
                  value={network}
                  onChange={(event) => {
                    setNework(event.target.value)
                  }}
                  required
                />
        </div>

        <button  type="submit"
            class="btn btn-primary radius-30" 
            onClick={onSubmit}>Update</button>
      </div>
    </div>
  );
};

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // Ensures the modal is on top of other content
  },
  modal: {
    position: 'relative',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)',
    width: '400px',
    textAlign: 'start',
  },
  content: {
    flex: '1',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
  paragraph: {
    margin: 0,
  }
};

export default SetCommissionModal;
